import type { FunctionComponent } from 'react'
import { useCallback } from 'react'
import { dynamicDatalayerPush } from '@which/shared'

import { ReviewsCompareTray } from '../../../../shared/components/ReviewsCompareTray/ReviewsCompareTray'
import type { Comparison } from '../../../../shared/types/ReviewsCompareTray'

export const CompareTrayWrapper: FunctionComponent<Props> = ({
  taxonomySlug = '',
  compareTrayItems = [],
  removeHandler,
  removeAllProducts,
}) => {
  const compareButtonClickHandler = useCallback(() => {
    dynamicDatalayerPush({
      eventAction: 'Compare - Compare Tray',
      eventCategory: 'Product Compare',
      eventLabel: '',
    })
  }, [])

  if ((compareTrayItems?.length ?? 0) <= 0) {
    return null
  }

  return (
    <ReviewsCompareTray
      taxonomySlug={taxonomySlug}
      products={compareTrayItems}
      removeHandler={removeHandler}
      removeAllHandler={removeAllProducts}
      compareHandler={compareButtonClickHandler}
      skipToContent={{
        text: 'Return to article',
        selector: 'h1[data-testid="headline"]',
      }}
    />
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  taxonomySlug: string
  compareTrayItems?: Comparison[]
  removeHandler: (businessKey: string) => void
  removeAllProducts: () => void
}
