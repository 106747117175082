import React from 'react'
import { GridItem } from '@which/seatbelt'

import { Breadcrumb } from '../../../../shared/components/Breadcrumb/Breadcrumb'
import { LocalFooter } from '../../../../shared/components/LocalFooter/LocalFooter'
import { articleBodyRenderer } from '../../../../shared/renderers/article-body-renderer'
import type { ArticleCrToolsPageData } from '../../article-page-types'
import { CRCarToolIntro } from '../../components/CRCarToolIntro'
import { HeroImageWithText } from '../../components/HeroImageWithText'
import styles from './ArticleCrToolPage.module.scss'

export const getCRToolsArticleComponents = (data: ArticleCrToolsPageData) => {
  const { articleBodyTemplate, consumerRightsFooter, breadcrumb } = data
  return {
    ArticleBody: () => (
      <GridItem span={{ large: 8 }} columnStart={{ large: 3 }}>
        {articleBodyRenderer(articleBodyTemplate, 'tool-article')}
      </GridItem>
    ),
    HeroImageWithText: (props: Parameters<typeof HeroImageWithText>[0]) => (
      <HeroImageWithText {...props} />
    ),
    CRCarToolIntro: () => (
      <GridItem span={{ medium: 10, large: 8 }} columnStart={{ medium: 2, large: 3 }}>
        <CRCarToolIntro className={styles.carToolIntro} />
      </GridItem>
    ),
    ConsumerRightsFooter: () => (
      <GridItem>
        <LocalFooter data={consumerRightsFooter} />
      </GridItem>
    ),
    Breadcrumb: () => <Breadcrumb {...breadcrumb} />,
  }
}
